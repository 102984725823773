<template>
  <div class="bet-result-view">
    <i class="close" @click="click_close"></i>
    <div class="content-success" v-if="bet_result">
      <div class="head">
        <i class="icon"></i>
        <span class="title">Successfully Purchased</span>
      </div>
      <p class="remark">KSH{{bet_score}} deducted from your account<br> Account balance <span class="orange">KSH{{balance}}</span></p>
    </div>
    <div class="content-failed" v-if="!bet_result">
      <div class="head">
        <!-- <i class="icon"></i> -->
        <span class="title">{{bet_msg}}</span>
      </div>
    </div>
  </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "BetResultView",
        computed: {
            ...mapGetters([
                "bet_msg",
                "bet_result",
                "bet_score",
                "balance"
            ]),
        },
        methods: {

            click_close() {
                this.$emit("close");
            }
        }
    }
</script>

<style scoped lang="less">
  .bet-result-view {
    padding: 30px 30px 70px;
    background-color: #FFFFFF;
    border-radius: 30px;
    width: 540px;
    /* min-height: 460px; */

    .close {
      display: block;
      float: right;
      width: 40px;
      height: 40px;
      background: url("../assets/img_close2.png") no-repeat center;
      background-size: contain;
    }

    .head {

    }

    .title {
      font-size: 36px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #333333;
      line-height: 40px;
    }

    .content-success {
      margin-top: 70px;
      .icon {
        display: block;
        margin: 0 auto;
        width: 104px;
        height: 104px;
        background: url("../assets/img_success.png") no-repeat center;
        background-size: contain;
      }

      .remark {
        font-size: 26px;
        font-family: ArialMT;
        color: #999999;
        line-height: 38px;
        margin-top: 43px;

        .orange {
          font-size: 27px;
          color: #E27A22;
        }
      }
    }

    .content-failed {
      margin-top: 110px;
      .icon {
        display: block;
        margin: 0 auto;
        width: 104px;
        height: 104px;
        background: url("../assets/img_failed.png") no-repeat center;
        background-size: contain;
      }
    }
  }
</style>

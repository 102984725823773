<template>
  <div class="lotto3-content">
    <PullRefresh @refresh="refresh">
    <!--    section-->
    <!-- <Banner :banners="banners2"></Banner> -->
    <!--    section-->
    <div class="lucky">
      <div class="timer">
        <div class="time">
          <i class="icon"></i>
          <span class="number">{{countdown_str}}</span>
        </div>
        <span class="draw-id">Draw ID: {{drawId}}</span>
        <!-- <div class="top">
          <div class="btn" @click="$router.push('/bets')">My Bets ></div>
        </div> -->
      </div>
      <div class="pick-title">
        <div class="tip">What Are Your 3 Lucky Numbers?</div>
        <van-button @click="click_random"><span>Random Pick</span></van-button>
      </div>
      <div class="input3">
        <van-field :class="focusId==1?'field focus':'field'" type="digit" :maxlength="1" v-model="in1" placeholder="-"
                   @click-input="click_field(1)" disabled="" @click="click_field(1)"></van-field>
        <van-field :class="focusId==2?'field focus':'field'" type="digit" :maxlength="1" v-model="in2" placeholder="-"
                   @click-input="click_field(2)" disabled="" @click="click_field(2)"></van-field>
        <van-field :class="focusId==3?'field focus':'field'" type="digit" :maxlength="1" v-model="in3" placeholder="-"
                   @click-input="click_field(3)" disabled="" @click="click_field(3)"></van-field>
      </div>
      <div class="tip1">Place Your Stake(KSH)</div>
      <div class="quickBtn">
        <div :class="stake==30?'item active':'item'" @click="setStake(30)">30</div>
        <div :class="stake==50?'item active':'item'" @click="setStake(50)">50</div>
        <div :class="stake==100?'item active':'item'" @click="setStake(100)">100</div>
      </div>
      <div class="stake">
        <div class="option">
          <!-- <i :class="stake>10?'icon-jian':'icon-jian disabled'" @click="change_stake(-1)"></i> -->
          <van-field style="width: 100%;" :class="focusId==4?'edit-stake focus':'edit-stake'" type="digit" :maxlength="4" v-model="stake" disabled @blur="on_stake_blur" @click="click_field(4)"></van-field>
          <!-- <i :class="stake<1000?'icon-jia':'icon-jia disabled'" @click="change_stake(1)"></i> -->
          <div class="doubleBet" @click="doubleBet">x2</div>
        </div>
        <!-- <div class="tip2">Place KSH20,you would win upto KSH7000</div> -->
        <div class="playType">
          <div class="top">
            <div :class="playType==1?'item active':'item'" @click="playType=1;tabsType=0">Any Order</div>
            <div :class="playType==0?'item active':'item'" @click="playType=0;tabsType=1">Straight Order</div>
          </div>
          <div class="bottom" v-if="playType==1">
              Total Odds: 7 in 10<span></span>Max Win: KSH {{stake*18}}
          </div>
          <div class="bottom" v-if="playType==0">
              Total Odds: 3 in 10<span></span>Max Win: KSH {{stake*333}}
          </div>
        </div>
        <van-button v-if="stake<10" class="bet" @click="onBet">Place Bet Now</van-button>
        <van-button v-else class="bet" @click="onBet" v-bind:class="{dobet:(canBet && stake>=10)}">
          <span v-if="stake>=20 && couponMoney>0">Place Bet Now KSH{{stake-couponMoney}} <span class="couponMoney">KSH{{couponMoney}}</span></span>
          <span v-else>Place Bet Now</span>
        </van-button>
      </div>
    </div>
    <!--    section-->
    <div class="table-info">
      <div class="payouts">
        <div class="title">
          <span>Shinda 3 Odds & Payout Table<i @click="$router.push('/winnersMode')"></i></span>
        </div>

        <van-tabs class="tabs" v-model="tabsType">
          <van-tab title="Any Order">
            <div class="desc">
              <div class="text1">Best Odds: 7 in 10, Highest Chance to Win</div>
              <div class="text2">Any Order Odds & Payout Table</div>
              <div class="text3">
                <span>By choosing Any Order, </span>
                <span class="green">you'll win by matching 1 numbers / 2 numbers / 3 numbers in any order.</span>
              </div>
            </div>
            <PayoutTable class="payout-table" :tableType="1"></PayoutTable>
          </van-tab>
          <van-tab title="Straight Order">
            <div class="desc">
              <div class="text1">Grand Wins: <br>333X of Your Bet Amount Win Upto KSH333,000</div>
              <div class="text2">Straight Order Odds & Payout Table</div>
              <div class="text3">
                <span>By choosing Right Order, </span>
                <span class="green">you'll win by matching 1 numbers / 2 numbers / 3 numbers in straight order.</span>
              </div>
            </div>
            <PayoutTable class="payout-table" :tableType="2"></PayoutTable>
          </van-tab>
        </van-tabs>
        <div class="tabs-tip">
          <div class="tabs-tip1">Best Odds</div>
          <div class="tabs-tip2">Grand Wins</div>
        </div>
      </div>
    </div>


    </PullRefresh>

    <!--popup-->
    <van-popup class="g_popup" v-model="popupBetSuccess" position="center" :close-on-click-overlay="true" @click="popupBetSuccess = false">
      <!-- <BetResultView v-on:close="close_popup('bet_success')"></BetResultView> -->
      <div class="successPopup">
        <div class="closeIcon"></div>
        <div class="top">
          <div class="icon"><img src="../assets/img_success2.png"></div>
          <div class="text1">Successfully Purchased</div>
          <div class="text2">The Draw Will Open in <span>{{countdown_str}}</span></div>
        </div>
      </div>
    </van-popup>
    <van-popup class="g_popup" v-model="popupBetFailed" position="center" :close-on-click-overlay="false">
      <BetResultView v-on:close="close_popup('bet_failed')"></BetResultView>
    </van-popup>
    <Calculation v-if="keyShow"
      :fatherNum="fatherNum"
      @confirmEvent="confirmEvent"
      @numberEvent="numberEvent"
      @deleteEvent="deleteEvent"
      @handleFewerKey="handleFewer"></Calculation>


  </div>
</template>

<script>
    import {formatDate} from "../utils/date";
    import Calculation from './NumKeypad';
    import PayoutTable from "./PayoutTable";
    import {checkInputStr, TransData} from "../utils/misc";
    import {CookiesKey, Env, gameSource, gameType, serve} from "../constant";
    import {mapGetters} from "vuex";
    //import Banner from "./Banner";
    import BetResultView from "./BetResultView";
    import PullRefresh from "./PullRefresh";

    export default {
        name: "Lotto3Content",
        props:{
          couponMoney: {
            type: Number
          },
          couponUnused: {
            type: Number
          },
          couponUsed: {
            type: Number
          },
        },
        components: {
          BetResultView,
          //Banner,
          PayoutTable,
          Calculation,
          PullRefresh
        },
        data() {
            return {
                in1: "",
                in2: "",
                in3: "",
                focusId: '',
                stake: 50,
                minStake: 30,
                maxStake: 1000,
                timer_random_handle: null,
                popupBetSuccess: false,
                popupBetFailed: false,
                keyShow: false, // 控制数字键盘显示/隐藏
                fatherNum: 0,
                playType: 1,
                tabsType: 0,
                betData: {},
            }
        },
        watch: {
            popupBetSuccess(newVal) {
              let that = this;
              if(!newVal){
                //弹框关闭后刷新码
                setTimeout(function(){
                  that.click_random();
                },500)
              }
            }
        },
        created() {
          this.click_random();

        },
        destroyed() {
          this.clear_timer_random_handle();
        },
        computed: {
            ...mapGetters([
                "openSec",
                "token",
                "closeSec",
                "drawId",
                "banners2"
            ]),
            pay_btn_text() {
                return `PAY KSH ${this.stake} NOW`;
            },
            countdown_str() {
                let openSec = this.openSec;
                let minutes = 0;
                let seconds = 0;
                if (openSec > 0) {
                    minutes = Math.floor(openSec / 60);
                    seconds = Math.floor(openSec % 60);
                }
                return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
            },
            canBet() {
                return this.closeSec > 0;
            },
        },
        methods: {
            doubleBet(){
              let newStake = this.stake*2;
              if(newStake>0){
                if(newStake>this.maxStake){
                  this.stake = this.maxStake;
                }else{
                  this.stake = newStake;
                }
              }
            },
            refresh (done) {
                let _this = this;
                setTimeout(() => {
                  _this.click_random();
                  done()    //我就想说这里，把状态归0
                }, 500)
            },
            setStake(stake){
              this.stake = stake;
            },
            handleFewer(){
              this.focusId = "";
              this.keyShow = false;
              window.scrollTo({
                top: 60,
                behavior: "smooth"
              });
            },
            numberEvent(num){
              if(this.focusId==4){
                let stake = this.stake+""+num;
                if(stake>=this.maxStake){
                  stake = 1000;
                }
                this.stake = parseInt(stake)
              }else{
                this['in'+this.focusId] = num;
                this.focusId++;
              }
            },
            confirmEvent(){
              this.focusId = "";
              this.keyShow = false;
              window.scrollTo({
                top: 60,
                behavior: "smooth"
              });
            },
            deleteEvent(){
              if(this.focusId==4){
                if(this.stake.length>0){
                  this.stake = this.stake.substring(0, this.stake.length - 1);
                }
              }else{
                this['in'+this.focusId] = "";
              }
            },
            onBet() {
                let that = this;
                /* if(!token){
                  this.$toast("Please Log In First.");
                  return;
                } */
                if (!this.canBet) {
                    return;
                }
                let token = new TransData()[CookiesKey.token];
                if(typeof(token)=="undefined"){
                  this.$toast("Please Log In First.");
                  return;
                }
                if (!token) {
                    try {
                        window.lottonow.login();
                        return;
                    } catch (e) {
                        if (process.env.NODE_ENV === Env.production) {
                            console.error(e);
                            return;
                        }
                    }
                }
                if (!checkInputStr(this.in1, this.in2, this.in3)) {
                    this.$toast("please enter");
                    return;
                }
                this.in1.trim();
                this.in2.trim();
                this.in3.trim();

                if(this.stake<this.minStake){
                  this.$toast("The minimum bet accepted is KSH"+this.minStake);
                  return;
                }
                if(this.stake>this.maxStake){
                  this.$toast("The maximum bet accepted is KSH"+this.maxStake);
                  return;
                }
                this.$toast.loading({
                    message: 'loading',
                    forbidClick: true
                });

                const sale5_res = this.$store.getters.serve_res(serve.data.sale);

                this.$store.dispatch('serve_req', {
                    that: this,
                    url: serve.user.bet,
                    method: 'get',
                    data: {
                        type: gameType,
                        issue: sale5_res.issuse,
                        data: `${this.in1}${this.in2}${this.in3}:${this.stake}`,
                        source: gameSource,
                        playType: this.playType
                    }
                }).then((data) => {
                    let d = data[0];
                    d.actiontime = formatDate(new Date(d.actiontime), 'hh:mm yyyy-MM-dd');
                    d.code = d.code.split("").join(",");
                    this.betData = d;
                    //this.$toast.clear();
                    //this.$store.commit("bet_result", true);
                    this.$g_event.$emit('bet_complete');
                    this.popupBetSuccess = true;
                    setTimeout(function(){
                      that.popupBetSuccess = false;
                    },3000)
                }).catch(() => {
                    this.$store.commit("bet_result", false);
                    this.popupBetFailed = true;
                })
            },
            close_popup(sign) {
                switch (sign) {
                    case "bet_success":
                        this.popupBetSuccess = false;
                        break;
                    case "bet_failed":
                        this.popupBetFailed = false;
                        break;
                }
            },
            click_random() {
                this.clear_timer_random_handle();
                let random = () => {
                    return Math.max(0, Math.floor(Math.random() * 10 - 0.1))
                };
                let count = 20;
                this.timer_random_handle = setInterval(() => {
                    this.in1 = random();
                    this.in2 = random();
                    this.in3 = random();
                    if (count-- < 0) {
                        this.clear_timer_random_handle();
                    }
                }, 10);
            },
            clear_timer_random_handle() {
                if (this.timer_random_handle != null) {
                    clearInterval(this.timer_random_handle);
                }
            },
            click_field(id) {
                //this[`in${id}`] = "";
                this.focusId = id;
                this.keyShow = true;
                //console.log("宽度",document.querySelectorAll('.lucky')[0].clientHeight)
                //console.log("top",document.querySelectorAll('.bet')[0].offsetTop)
                window.scrollTo({
                  top: 260,
                  behavior: "smooth"
                });
                if(id==4){
                  this.fatherNum = this.stake;
                }else{
                  this.fatherNum = this[`in${id}`];
                }
            },
            change_stake(symbol) {
                let inc = symbol;
                let stake = parseInt(this.stake) + inc;
                console.log(symbol)
                if(symbol<0 && stake<this.minStake){
                    this.$toast("The minimum bet accepted is KSH"+this.minStake);
                    return;
                }else if(symbol>0 && stake>=this.maxStake){
                    this.$toast("The maximum bet accepted is KSH"+this.maxStake);
                    return;
                }
                this.stake = stake;
                this.on_stake_blur();
            },
            on_stake_blur() {
              /* if(this.stake*1>this.maxStake){
                this.$toast("The maximum bet accepted is KSH"+this.maxStake);
              }else if(this.stake*1<this.minStake){
                this.$toast("The minimum bet accepted is KSH"+this.minStake);
              }else{
                this.stake = Math.max(this.minStake, Math.min(this.maxStake, this.stake));
              } */
            }
        }
    }
</script>

<style scoped lang="less">
  @import "../styles/var";

  @lucky-margin: 60px;
  .lotto3-content {
    font-family: Ubuntu;

    //background-color: @theme-color;
    padding-bottom: 6px;
    min-height: @content-min-height;

    /deep/ .banner {
      margin: 0 @margin;
      height: 181px;
      border-radius: 10px;
      overflow: hidden;

      .van-image {
        height: 100%;
      }
    }

    .lucky {
      margin: @margin;
      //border-radius: 30px;
      //background: #FFFFFF url("../assets/bg_tr.png") no-repeat top right;
      //background-size: 424px 184px;
      overflow: hidden;

      .timer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .time {
          background: #FEEDE4;
          display: flex;
          justify-items: flex-start;
          align-items: center;
          border-radius: 19px;
          padding: 2px 5px;
          .icon {
            width: 24px;
            height: 24px;
            background: url("../assets/img_clock.png") no-repeat center;
            background-size: contain;
          }

          .number {
            font-size: 24px;
            font-weight: 500;
            font-family: DigitalCounter7;
            color: #F55000;
            margin-left: 20px;
            margin-right: 16px;
          }
        }

        .draw-id {
          font-size: 24px;
          font-family: Arial-BoldMT, Arial;
          font-weight: 400;
          color: #737373;
          line-height: 36px;
        }

        .top{
          text-align: right;
          line-height: 22px;
          .btn{
            font-size: 24px;
            border-radius: 20px;
            background-color: #008250;
            display: inline-block;
            color: #fff;
            padding: 10px 20px;
            font-weight: bold;
          }
        }
      }

      .pick-title {
        font-family: ArialMT;
        line-height: 37px;
        padding: 0 @lucky-margin;
        margin-top: 10px;

        .tip{
          color: #3C3C3C;
          font-size: 28px;
          font-weight: 500;
          margin-top: 30px;
          margin-bottom: 20px;
        }


        /deep/ .van-button {
          font-size: 24px;
          font-family: ArialMT;
          color: #FFFFFF;
          height: 64px;
          background: #F55000;
          border-radius: 32px;
          padding: 0 25px;

          .van-button__text {
            display: flex;
            justify-items: flex-start;
            justify-content: space-between;
            align-items: center;
          }

          .icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url("../assets/img_refresh.png") no-repeat center;
            background-size: contain;
            margin-right: 6px;
          }
        }
      }

      .input3 {
        display: flex;
        justify-items: flex-start;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        margin-top: 40px;
        /deep/ .field {
          width: 208px;
          height: 160px;
          border-radius: 24px;
          border: 1px solid #008250;

          .van-field__body {
            width: 100%;
            height: 100%;
            font-size: 72px;
            font-family: Arial-Black, Arial;
            font-weight: 900;
            color: #333333;
            line-height: 101px;
          }

          .van-field__control {
            text-align: center;
            color: #323233;
            -webkit-text-fill-color: #323233
          }
          &::after{
            display: none;
          }
        }
        /deep/ .focus{
          background: #008250;
          .van-field__control {
            text-align: center;
            color: #FFF;
            -webkit-text-fill-color: #FFF
          }
        }
      }
      .doubleBet{
        position: absolute;
        top: 8px;
        right: 5px;
        background-color: #008250;
        border-radius: 40px;
        color: #FFF;
        font-size: 40px;
        padding: 0 40px;
        height: 64px;
        line-height: 64px;
      }
      .tip1{
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 28px;
        font-weight: bold;
      }
      .tip2{
        font-weight: bold;
        font-size: 28px;
      }
      .stake {

        /deep/ .bet {
          width: 100%;
          height: 96px;
          background-color: silver;
          border-radius: 48px;
          font-size: 32px;
          font-family: Arial-Black, Arial;
          font-weight: 900;
          color: #FFFFFF;
          line-height: 56px;
          margin-bottom: 10px;
          .couponMoney{
            font-size: 14px;
            font-weight: normal;
            text-decoration: line-through;
          }
        }

        /deep/ .dobet {
          background-color: #008250;
        }

        .option {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0;
          &::before{
            font-size: 50px;
            color: #009901;
            content: "KSH";
            position: absolute;
            top: 10px;
            left: 20px;
            font-weight: bold;
            z-index: 1;
          }
          .icon-jian {
            display: inline-block;
            width: 84px;
            height: 84px;
            background-image: url("../assets/btn_jian.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
          .icon-jian.disabled{
            background-image: url("../assets/btn_jian_disabled.png");
          }

          /deep/ .edit-stake {
            width: 370px;
            height: 80px;
            border-radius: 40px;
            border: 1px solid #008250;

            .van-field__body {
              width: 100%;
              height: 100%;
              font-size: 48px;
              font-family: Arial-Black, Arial;
              font-weight: 900;
              color: #333333;
              line-height: 68px;
            }

            .van-field__control {
              text-align: center;
            color: #323233;
            -webkit-text-fill-color: #323233
            }
          }
          /deep/ .focus{
            border-color: #DD6601;
            box-shadow: 0 0 10px #DD6601;
            background: #fff;
          }

          .icon-jia {
            display: inline-block;
            width: 84px;
            height: 84px;
            background-image: url("../assets/btn_jia.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
          .icon-jia.disabled{
            background-image: url("../assets/btn_jia_disabled.png");
          }
        }
      }

      .playType{
        background-color: #FFFACC;
        border-radius: 24px;
        .top{
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 20px;
          .item{
            font-weight: bold;
            color: #A0A0A0;
            border-radius: 32px;
            background-color: #FFF;
            border: 2px solid #FFE600;
            font-size: 24px;
            width: 256px;
            padding: 18px 0;
            text-align: center;
            transition: all .2s;
            margin: 0 10px;
          }
          .active{
            color: #3C3C3C;
            background-color: #FFE600;
          }
        }
        .bottom{
          padding: 20px 0;
          font-size: 28px;
          color: #3C3C3C;
          font-weight: 500;
          span{
            display: inline-block;
            width: 50px;
            height: 1px;
          }
        }
      }

      .tip3{
        margin-top: 10px;
        font-size: 24px;
        color: #333;
        padding: 20px;
        border-radius: 10px;
        background-color: #FFFF00;
        text-align: left;
      }

    }

    .table-info {
      margin: @margin;
      background-color: #E5F2ED;
      border-radius: 30px;
      overflow: hidden;
      position: relative;
      .title {
        padding: 30px 0;
        font-size: 34px;
        background-color: #CEE7DD;

        span{
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #3C3C3C;
          position: relative;
          i{
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-left: 10px;
            content: "";
            background-image: url(../assets/icon/icon-wh.png);
            background-size: cover;
            vertical-align: sub;
          }
        }
      }
      .tabs-tip{
        width: 100%;
        position: absolute;
        top: 140px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        div{
          background-color: #FFE600;
          color: #F55000;
          font-size: 20px;
          font-weight: bold;
          border-radius: 16px;
          padding: 2px 20px;
        }
      }
      /* .tabs-tip1{
        left: 94px;
      }
      .tabs-tip2{
        left: 430px;
      } */
      /deep/.van-tabs__wrap{
        margin-top: 60px;
        border-bottom: 1px solid #CEE7DD!important;
        .van-tabs__nav{
          background-color: #E5F2ED;
        }
      }
      .van-tabs__content{
        .desc{
          padding: 0 60px;
          .text1{
            font-size: 24px;
            font-weight: bold;
            color: #F55000;
            margin-top: 20px;
          }
          .text2{
            font-size: 28px;
            font-weight: bold;
            color: #3C3C3C;
            margin-top: 20px;
          }
          .text3{
            margin-top: 20px;
            font-size: 24px;
            color: #737373;
            .green{
              color: #008250;
            }
          }
        }
      }
      .payout-table {
        margin-bottom: 20px;
        margin-top: 20px;
      }

      .explanation {
        display: flex;
        justify-items: flex-start;

        .m-pesa {
          width: 320px;
          height: 489px;
        }

        .sms {
          width: 320px;
          height: 489px;
        }
      }
    }

    .quickBtn{
      display: flex;
      align-items: center;
      justify-items: flex-start;
      justify-content: space-between;
      .item{
        border: 1px solid #008250;
        width: 208px;
        padding: 24px 0;
        font-size: 28px;
        font-weight:bold;
        border-radius: 40px;
      }
      .active{
        background-color: #008250;
        color: #FFF;
      }
    }

    .successPopup{
      background-color: #FFF;
      border-radius: 48px;
      padding: 80px 50px;
      font-size: 32px;
      width: 400px;
      position: relative;
      .closeIcon{
        position: absolute;
        top: 32px;
        right: 32px;
        width: 40px;
        height: 40px;
        background-image: url(../assets/img_close3.png);
        background-size: contain;
      }
      .top{
        .icon{
          img{
            width: 160px;
            height: 160px;
          }
        }
        .text1{
          margin-top: 34px;
          color: #008250;
          font-weight: bold;
        }
        .text2{
          font-size: 28px;
          margin-top: 18px;
          span{
            font-weight: bold;
            color: #F55000;
          }
        }
      }
      .bottom{
        font-size: 28px;
        margin-top: 30px;
        .item{
          display: flex;
          padding: 30px 0;
          border-bottom: 1px solid #F0F0F0;
          .label{
            color: #737373;
          }
          .value{
            flex: 1;
            text-align: right;
            color: #3C3C3C;
            font-weight: bold;
          }
        }
      }
    }
  }
</style>

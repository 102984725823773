<template>
  <div class="results-content">
    <van-popup class="g_popup" v-model="popupDate" position="bottom">
      <van-datetime-picker
        class="date-picker" v-model="currentDate" type="date" title="Select Date"
        :min-date="minDate" :max-date="maxData" confirm-button-text="Confirm"
        cancel-button-text="Cancel"
        @confirm="on_date_confirm" @cancel="on_date_cancel">
      </van-datetime-picker>
    </van-popup>
    <PullRefresh @refresh="refresh">
      <div class="result-top" v-if="is_top_level">
        <!--    section-->
        <!--      <Banner :banners="banners2"></Banner>-->
        <!--    section-->
        <div class="result">
          <div class="date">
            <div class="title" @click="on_date_click">
              <i class="icon"></i>
              <span class="date-text">{{select_date_str}}</span>
              <i class="icon2"></i>
            </div>
          </div>
          <table class="table">
            <thead>
            <tr class="title-th">
              <th class="a">Draw ID</th>
              <th class="b">Winning<br/>Numbers</th>
              <th class="c">Winners</th>
              <th class="d"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <td class="a">
                <div style="font-weight: bold;">{{item.issuse}}</div>
                <div class="time">{{formatTime(item.opentime)}}</div>
              </td>
              <td class="b" v-if="item.res==null">Waiting</td>
              <td class="b codeHtml" v-else>{{item.res}}</td>
              <td class="c" style="color: #F55000;">{{item.winnums>0?item.winnums:'0'}}</td>
              <td class="d">
                <span @click="showDetails(item)">See Details &gt; </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="g_div_table winners" v-if="!is_top_level">
        <WinnersView v-on:back="on_back"></WinnersView>
      </div>
      <div class="control-strip">
        <van-button class="button previous" @click="on_previous" v-bind:class="{button2:can_previous}">Previous
        </van-button>
        <div class="text">
          <span class="cur">{{curPage}}</span>
          <span>/</span>
          <span class="max">{{maxPage}}</span>
        </div>
        <van-button class="button next" @click="on_next" v-bind:class="{button2:can_next}">Next</van-button>
      </div>
    </PullRefresh>
  </div>
</template>

<script>
    import WinnersView from "./WinnersView";
    import {gameType, serve} from "../constant";
    import {formatDate} from "../utils/date";
    import {mapGetters} from "vuex";
    import PullRefresh from "./PullRefresh";

    export default {
        name: "ResultsContent",
        components: {WinnersView,PullRefresh},
        data() {
            return {
                curPage: 1,
                limit: 10,
                maxPage: 0,
                is_top_level: true,
                select_idx: 0,
                results: [],
                select_date_str: "",

                popupDate: false,
                currentDate: new Date(),
                minDate: new Date(2020, 0, 1),
                maxData: new Date(2030, 11, 31)
            }
        },
        created() {
            this.update_select_date_str();
            this.questResults();

            //this.$g_event.$on('sale_update', this.questResults.bind(this));
        },
        computed: {
            ...mapGetters([
                "drawId",
                "banners2"
            ]),
            can_previous() {
                return this.curPage > 1;
            },
            can_next() {
                return this.curPage < this.maxPage;
            },
            details() {
                return this.result[this.select_idx];
            },
        },
        methods: {
            refresh (done) {
                let _this = this;
                setTimeout(() => {
                  _this.questResults();
                  done()    //我就想说这里，把状态归0
                }, 500)
            },
            questResults() {
                this.$store.dispatch('serve_req', {
                    url: serve.data.list,
                    method: 'get',
                    data: {
                        page: this.curPage,
                        limit: this.limit,
                        type: gameType,
                        date: this.select_date_str
                    }
                }).then((data) => {
                    this.maxPage = data.pages;
                    this.results = data.records;
                    // data.records.forEach((item, index) => {
                    //     this.$set(this.results, index, item);
                    // });
                })
            },
            click_check_details(index) {
                let result = this.results[index];
                if (result) {
                    this.$http.get(serve.api.winners).then((data) => {
                        result.winners = data.winners;
                        this.$store.commit("winners", data.winners);
                        this.is_top_level = false;
                    })
                }
            },
            on_back() {
                this.is_top_level = true;
            },
            on_date_click() {
                this.popupDate = true;
                this.currentDate = new Date(this.select_date_str);
            },
            on_date_confirm() {
                this.popupDate = false;
                this.curPage = 1;
                this.update_select_date_str();
                this.questResults();
            },
            on_date_cancel() {
                this.popupDate = false;
            },
            update_select_date_str() {
                this.select_date_str = formatDate(this.currentDate, 'yyyy-MM-dd');
            },
            formatTime(time) {
                return formatDate(new Date(time), 'MM/dd hh:mm');
            },
            waiting(drawId) {
                return this.drawId === drawId;
            },
            showDetails(item){
              //this.$router.push(`/winnersRecord?issuse=${item.issuse}&code=${item.res}&opentime=${item.opentime}`)
              this.$router.push({ name: 'WinnersRecord', params: { issuse: item.issuse, code: item.res, opentime: item.opentime}})
            },
            on_previous() {
                if (this.can_previous) {
                    this.curPage--;
                    this.questResults();
                }
            },
            on_next() {
                if (this.can_next) {
                    this.curPage++;
                    this.questResults();
                }
            },
        }
    }
</script>

<style scoped lang="less">
  @import "../styles/var";

  .results-content {
    padding-bottom: 6px;
    min-height: @content-min-height;

    /deep/.van-picker{
      .van-picker-column,.van-picker__cancel, .van-picker__confirm,.van-picker__title{
        font-size: 24px;
      }
      .van-picker__toolbar{
        height: 60px;
      }
    }
    .result-top {

      /deep/ .banner {
        margin: 0 @margin;
        height: 181px;
        border-radius: 10px;
        overflow: hidden;

        .van-image {
          height: 100%;
        }
      }

      .result {
        margin-top: 0;
        background: #FFFFFF;
        border-radius: 30px;
        overflow: hidden;

        .date {
          display: flex;
          justify-content: flex-start;
          height: 110px;

          .title {
            display: flex;
            justify-items: flex-start;
            align-items: center;

            .icon {
              margin: 0 31px;
              display: block;
              width: 40px;
              height: 40px;
              background: url("../assets/img_date.png") no-repeat center;
              background-size: contain;
            }

            .date-text {
              font-size: 32px;
              font-family: Arial-BoldMT, Arial;
              font-weight: normal;
              color: #333333;
              line-height: 37px;
            }

            .icon2 {
              margin: 0 12px;
              display: block;
              width: 20px;
              height: 16px;
              background: url("../assets/img_jiantou_b.png") no-repeat center;
              background-size: contain;
            }
          }
        }

        /deep/ .date-picker {
          .van-picker__toolbar {
            height: 80px;
          }

          .van-picker-column {
            font-size: 28px;
          }

          .van-picker__cancel, .van-picker__confirm, .van-picker__title {
            font-size: 30px;
            line-height: 35px;
          }
        }

        .table {
          border-spacing: 0;
          border-collapse: collapse;

          tbody {
            display: block;
            /* max-height: calc(100vh - 650px);
            overflow-y: scroll; */
            -webkit-overflow-scrolling: touch; // 为了滚动顺畅
          }

          tr {
            height: 86px;
            border-bottom: 1px solid #DDDDDD;

            display: table;
            width: 100%;
            table-layout: fixed;
          }

          th {
            padding-right: 15px;
            text-align: left;
          }

          td {
            height: 25px;
            font-size: 26px;
            font-family: ArialMT;
            color: #333333;
            line-height: 32px;
            text-align: left;
          }

          .title-th {
            height: 86px;
            background: #FAFAFA;
            font-size: 24px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #737373;
            line-height: 26px;
            text-align: center;
            border: none;
          }

          .a {
            width: 180px;
            padding-left: @td-padding;
            .time{
              font-size: 22px;
              color: #737373;
            }
          }

          .b {
            width: 160px;
          }
          .codeHtml{
            letter-spacing:2px;
          }

          .c {
            width: 110px;
          }

          .d {
            width: 180px;
            color: #008250;
            .arrow {
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url("../assets/img_jiantou_r.png") no-repeat center;
              background-size: contain;
              margin-left: 8px;
              margin-right: 4px;
            }
          }
        }
      }
    }
    .control-strip {
      display: flex;
      justify-items: flex-start;
      justify-content: center;
      align-items: center;
      padding: 37px 0;

      .button {
        width: 132px;
        height: 38px;
        background: #FFFFFF;
        border-radius: 19px;
        border: 1px solid #D0D0D0;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #C0C0C0;
        line-height: 30px;
      }

      .button2 {
        border: 1px solid #333333;
        color: #333333;
      }

      .text {
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        margin: 0 35px;

        span {
          margin: 0 3px;
        }

        .cur {
          color: #009901;
        }
      }
    }
  }

</style>

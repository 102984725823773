<template>
  <div class="winners-view">
    <!--    section-->
    <div class="title-back" @click="click_back">
      <i class="icon"></i>
      <span>LOTTO 3 Winners</span>
    </div>
    <!--    section-->
    <div class="result-info">
      <table class="table">
        <tbody>
        <tr>
          <td class="name">Draw ID:</td>
          <td class="right">{{0}}</td>
        </tr>
        <tr>
          <td class="name">Draw Time:</td>
          <td class="right">{{0}}</td>
        </tr>
        <tr>
          <td class="name2">Winning Numbers:</td>
          <td class="right number3">
            <i>{{0}}</i>
            <i>{{0}}</i>
            <i>{{0}}</i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!--    section-->
    <div class="details">
      <table class="table">
        <thead>
        <tr class="title-th">
          <th class="a">Phone<br/>Number</th>
          <th class="b">Ticket<br/>ID</th>
          <th class="c">Picks</th>
          <th class="d">Stake</th>
          <th class="e">Winnings</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(info, index) in winners" :key="index">
          <td class="a">{{info.phoneNumber}}</td>
          <td class="b">{{info.ticketId}}</td>
          <td class="c">{{info.picks}}</td>
          <td class="d">{{info.stake}}</td>
          <td class="e">{{info.winnings}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "WinnersView",
        created() {
        },
        computed: {
            ...mapGetters([
                "winners"
            ]),
        },
        methods: {
            click_back() {
                this.$emit("back")
            }
        }
    }
</script>

<style scoped lang="less">
  @import "../styles/var";

  .winners-view {
    margin: 0 @margin @margin;
    border-radius: 30px;
    overflow: hidden;
    background: #FFFFFF url("../assets/bg_tr.png") no-repeat top right;
    background-size: 424px 184px;

    .title-back {
      font-size: 32px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #333333;
      line-height: 36px;
      text-align: left;
      margin-top: 48px;
      margin-left: 30px;
      display: flex;
      align-items: center;

      .icon {
        border: 3px solid;
        border-color: transparent transparent #999999 #999999;
        width: 20px;
        height: 20px;
        display: inline-block;
        transform: rotate(45deg);
        border-radius: 6px;
        margin: 0 10px;
      }
    }

    .result-info {
      padding-left: 40px;
      margin-top:45px;

      .table {
        text-align: left;

        tr {
          height: 70px;
        }

        td {
          width: 350px;
        }

        .name {
          font-size: 28px;
          font-family: ArialMT;
          color: #333333;
          line-height: 32px;
        }

        .name2 {
          font-size: 32px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #009901;
          line-height: 37px;
        }

        .right {
          font-size: 32px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #333333;
          line-height: 37px;
        }

        .number3 {
          i {
            display: inline-block;
            width: 63px;
            height: 64px;
            background: #009901;
            border-radius: 12px;
            margin-right: 20px;
            font-size: 38px;
            font-family: Arial-Black, Arial;
            font-weight: 900;
            color: #FFFFFF;
            line-height: 64px;
            text-align: center;
            font-style: normal;
          }
        }
      }
    }

    .details {
      .table {
        border-spacing: 0;
        border-collapse: collapse;
        margin: 50px 0;

        tr {
          height: 86px;
          border-bottom: 1px solid #DDDDDD;
        }

        th {
          padding-left: 15px;
        }

        td {
          height: 25px;
          font-size: 22px;
          font-family: ArialMT;
          color: #333333;
          line-height: 26px;
        }

        .title-th {
          height: 86px;
          background: #F2FAF2;
          font-size: 22px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #009901;
          line-height: 26px;
          text-align: left;
          border: none;
        }

        .a {
          width: 130px;
        }

        .b {
          width: 190px;
        }

        .c {
          width: 120px;
        }

        .d {
          width: 190px;
        }

        .e {
          width: 190px;
        }
      }
    }
  }
</style>
